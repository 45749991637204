import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import 'src/assets/smtp/smtp.js' ;
declare let Email : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  FormData: FormGroup;
  public isSubmitting = false;

  constructor(private builder: FormBuilder, private router: Router) { }



  ngOnInit(): void {
    this.FormData = this.builder.group({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.compose([Validators.pattern('[- +()0-9]+'),Validators.minLength(8),Validators.required])]),
      email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      message: new FormControl('', [Validators.compose([Validators.minLength(10),Validators.maxLength(255),Validators.required])]),
      recaptchaReactive: new FormControl('', [Validators.required])
    })
  }

  isFieldValid(field: string) {
    return !this.FormData.get(field).valid && this.FormData.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  onSubmit() {
    console.log(this.FormData);
    if (this.FormData.valid) {
      console.log('Form Submitted');
      this.sendRegisterEmail();
    } else {
      this.validateAllFormFields(this.FormData);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      //console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public sendRegisterEmail() {

    this.isSubmitting = true;

    Email.send({
    Host : 'smtp.gmail.com',
    Username : 'webmailer@tarjetaceleste.com',
    Password : 'uyxsg!oimdR.8Fzjq4Mtu79',
    To : 'teayudo@unen.uy',
    From : 'webmailer@tarjetaceleste.com',
    Subject : 'Nueva Consulta en UNEN',
    Body : '<i>Este correo ha sido generado automáticamente por el formulario de Contacto de la web de UNEN.</i><br/>'+
    ' <br/> <b>Nombre: </b>'+this.FormData.controls.name.value +
    ' <br/> <b>Teléfono: </b>'+this.FormData.controls.phone.value +
    ' <br/> <b>Email: </b>'+this.FormData.controls.email.value +
    ' <br/><br/> <b>Mensaje: </b>'+this.FormData.controls.message.value +'<br>'}).then( message => {

      if (message == "OK") {
        this.isSubmitting = false;
        this.router.navigate(['/contact-success']);
      }
      else {
        this.isSubmitting = false;
        this.router.navigate(['/contact-error']);
      }
    });
  }

}

