<section id="home" class="masthead">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-lg-6 col-md-8"> 
                <table style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                    <thead>
                        <tr style="background-color: #3b3b3b; padding: 3px 0; line-height: 68px; text-align: center; color: #fff; font-size: 24px; font-weight: 700; letter-spacing: 1px;">
                            <th scope="col"><img src="assets/img/unen-white.png" width="25%" height="auto" alt="UNEN"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td style="padding: 48px 24px 0; color: #a9b11c; font-size: 20px; font-weight: 600;">
                                <i class="fa fa-times-circle"></i>
                                No hemos podido recibir su mensaje correctamente.
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 15px 24px 15px; color: #43546b;">
                                Estamos teniendo inconvenientes para hacerlo. Le pedimos disculpas.
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px 15px; color: #43546b;">
                                Por favor escríbanos directamente a <a href="mailto:teayudo@unen.uy" style="color:#a9b11c">teayudo@unen.uy</a> y con gusto responderemos sus consultas a través de ese medio.
                                <br>¡Muchas gracias por comprendernos!
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px 15px; color: #43546b;">
                                El Equipo de UNEN
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px 15px;">
                                <a [routerLink]="['/']" routerLinkActive="active" class="btn btn-primary text-center" style="background-color: #d6de27;border-color: #d6de27; color:#3b3b3b">Volver al inicio</a>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 16px 8px; color: #43546b; background-color: #f8f9fc; text-align: center;">
                                © 2021 UNEN.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<app-footer></app-footer>