<section id="home" class="masthead" style="padding: 150px 0px;">
    <div class="intro-body">
        <div class="container"><img class="img-fluid" style="height: 200px;margin: 10px;max-width: 75%;width: 400px;" src="assets/img/unen-white.png"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-xl-0 text-center mx-auto" style="padding: 30px;">
                    <div class="menu-line"><a class="a-menu" href="#about" style="margin:15px;">Nosotros</a></div>
                    <div class="menu-line"><a class="a-menu" href="#product">Propuesta</a></div>
                    <div class="menu-line"><a class="a-menu-demo" href="https://paracart-demounen.web.app/" target="_blank">DEMO</a></div>
                    <div class="menu-line"><a class="a-menu" href="#contact">Contacto</a></div>
                    <div class="btn-group" role="group"></div>
                </div>
            </div>
            <div class="row" style="padding-bottom: 21px;">
                <div class="col">
                    <a href="https://www.tarjetaceleste.com/" target="_blank">
                        <img class="celeste-logo" src="assets/img/celeste-white.png">
                    </a>
                    <a href="https://theparadigmcity.com/" target="_blank">
                        <img class="paradigm-logo" src="assets/img/paradigmcity.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="text-center content-section" id="about" style="padding: 150px 0px;border-top: 1px solid rgba(162,162,162,0.3) ;border-bottom: 1px solid rgba(162,162,162,0.3) ;">
    <div class="container about-container">
        <div class="row">
            <div class="col-12 col-sm-11 col-md-12 col-lg-5 col-xl-5 text-left mx-auto">
                <h2 class="text-left headings-line">Nosotros</h2>
                <p class="p-main">Somos un equipo de gente dedicada a los negocios. De distintas nacionalidades, unos más arriesgados y dinámicos, otros más experimentados y serenos.<br>Somos gente con formación en diversas disciplinas como la informática, las finanzas, el marketing, la gestión de servicios.<br>El entusiasmo en común por los desarrollos tecnológicos nos reunió en 2019. Decidimos combinar conocimientos para desarrollar UNEN, una plataforma de comercio electrónico orientada exclusivamente a pequeñas y medianas empresas.<br></p>
                <p class="p-main">Nos une la pasión por dar servicio. Una cualidad que facilita la comprensión de nuestro cliente.<br>Somos eficaces a la hora de generar oportunidades comerciales. Nos importa alcanzar resultados exitosos y duraderos en alianza con el Empresario Pyme.</p>
                <p></p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-mobile-about"></div>
        </div>
        <div class="row row-mobile-about">
            <div class="col"><img class="slides" src="assets/img/slide1.png"></div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                <a href="https://www.tarjetaceleste.com/" target="_blank">
                    <img class="celeste-logo" src="assets/img/celeste.png">
                </a>
                <a href="https://theparadigmcity.com/" target="_blank">
                    <img class="paradigm-logo" src="assets/img/paradigmcity-black.png">
                </a>
            </div>
        </div>
    </div>
</section>
<section class="text-center content-section" id="product" style="padding: 150px 0px;background: #fbf9f9;">
    <div class="container product-container">
        <div class="row">
            <div class="col text-left mx-auto">
                <h2 class="text-left headings-line">Nuestra propuesta</h2>
                <h4 style="margin-bottom: 10px;"><i class="fa fa-square"></i> Plataformas digitales</h4>
                <p class="p-main"><strong>El comercio electrónico es una herramienta para aumentar sus ventas y abarcar más público. 
                    UNEN hoy le propone dar inicio a una relación comercial duradera con el fin de sumarle a su empresa 
                    tecnología clave para este tiempo. Unamos fuerzas en busca de futuras ideas innovadoras.</strong></p>
                <p></p>
            </div>
            <div class="col col-mobile-product"></div>
        </div>
        <div class="row">
            <div class="col text-left mx-auto">
                <h4 style="margin: 0px;margin-bottom: 10px;margin-top: 25px;">Su plataforma de ventas en línea</h4>
            </div>
            <div class="col col-mobile-product"></div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" style="margin-top: 5px;padding-right: 10px;padding-left: 10px;padding-top: 10px;padding-bottom: 10px;">
                <div class="card">
                    <div class="card-body" style="padding: 5px;background: rgba(237,237,237,0.6);">
                        <h4 class="card-title">Tecnología</h4>
                        <ul>
                            <li><i class="fa fa-chevron-right"></i> Estética simple, se reduce la carga visual.<br></li>
                            <li><i class="fa fa-chevron-right"></i> Diseño centrado en el usuario, una tarea por vez.</li>
                            <li><i class="fa fa-chevron-right"></i> Pantallas claras, con información precisa.</li>
                            <li><i class="fa fa-chevron-right"></i> Diseño basado en Servicios Google.</li>
                            <li><i class="fa fa-chevron-right"></i> Accesos: Web App, PC, Celular</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" style="margin-top: 5px;padding-right: 10px;padding-left: 10px;padding-top: 10px;padding-bottom: 10px;">
                <div class="card">
                    <div class="card-body" style="padding: 5px;background: rgba(237,237,237,0.6);">
                        <h4 class="card-title">servicios</h4>
                        <ul>
                            <li><i class="fa fa-chevron-right"></i> Plataforma organizada según el negocio del cliente.<br></li>
                            <li><i class="fa fa-chevron-right"></i> Carga inicial de productos.</li>
                            <li><i class="fa fa-chevron-right"></i> Entrenamiento al usuario.</li>
                            <li><i class="fa fa-chevron-right"></i> Soporte permanente.</li>
                            <li><i class="fa fa-chevron-right"></i> Acompañar el desarrollo del negocio.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-mobile-product"></div>
        </div>
        <div class="row">
            <div class="col-lg-5">
                <p class="p-main" style="font-style: italic;margin-top: 25px;"><strong><em>Nuestro foco es entender la necesidad del cliente.</em></strong><br><strong><em>Somos eficaces a la hora de generar oportunidades comerciales.</em></strong><br><strong><em>Nos importa alcanzar resultados exitosos pensados para su negocio.</em></strong></p>
            </div>
            <div class="col col-mobile-product"></div>
        </div>
        <div class="row">
            <div class="col-lg-5">
                <h5 class="text-left" style="margin-bottom: 10px;">ADICIONALES DE SERVICIO</h5>
                <p class="p-main"><i class="fa fa-square"></i><strong> Integración con pasarela de pagos. <i class="fa fa-square"></i> Integración con WhatsApp. <i class="fa fa-square"></i> Otras integraciones a medida. <i class="fa fa-square"></i> Campañas en Google Adwords. <i class="fa fa-square"></i> Redes sociales. <i class="fa fa-square"></i> Diseño institucional. <i class="fa fa-square"></i> Gestión de contenidos.</strong></p>
            </div>
            <div class="col col-mobile-product"></div>
        </div>
        <div class="row row-img-product">
            <div class="col">
                <img class="slides" src="assets/img/slide2.png">
            </div>
        </div>
    </div>
    <div class="container" style="border-radius: 15px;margin-top: 10px;margin-bottom: 10px;">
        <div class="row">
            <div class="col">
                <a href="https://www.tarjetaceleste.com/" target="_blank">
                    <img class="celeste-logo" src="assets/img/celeste.png">
                </a>
                <a href="https://theparadigmcity.com/" target="_blank">
                    <img class="paradigm-logo" src="assets/img/paradigmcity-black.png">
                </a>
            </div>
        </div>
    </div>
</section>
<section class="text-center content-section" id="contact" style="border-top: 1px solid rgba(162,162,162,0.3) ;border-bottom: 1px solid rgba(162,162,162,0.3) ;">
    <div class="container">
        <div class="row">
            <div class="col-md-7 col-lg-7 col-xl-7" style="padding-left: 15px;">
                <h2 class="text-left headings-line">Atención Comercial</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7 col-lg-7 col-xl-7 mx-auto" style="margin-bottom: 25px;">
                <div class="form-group">
                    <form [formGroup]="FormData" name="contact-form" id="contact-form">
                        <div class="form-row">
                            <div class="col-lg-6 col-xl-6">
                                <div class="form-group position-relative" [ngClass]="displayFieldCss('name')">
                                    <label class="p-main">Nombre *</label>
                                    <input class="form-control" type="text" formControlName="name" required id="name" placeholder="Nombre:">
                                    <div *ngIf="isFieldValid('name')" class="alert">
                                        Ingrese un Nombre válido
                                    </div>
                                </div>
                            </div>
                            <div class="col" [ngClass]="displayFieldCss('phone')">
                                <div class="form-group position-relative" style="text-align: left">
                                    <label class="p-main">Teléfono *</label>
                                    <input class="form-control" type="tel" formControlName="phone" required id="phone" placeholder="Teléfono:">
                                    <div *ngIf="isFieldValid('phone')" class="alert">
                                        Ingrese un Teléfono válido
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="form-group position-relative" [ngClass]="displayFieldCss('email')">
                                    <label class="p-main">Email *</label>
                                    <input class="form-control" type="email" formControlName="email" required id="email" placeholder="Email:">
                                    <div *ngIf="isFieldValid('email')" class="alert">
                                        Ingrese un Email válido
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group position-relative" [ngClass]="displayFieldCss('message')">
                                    <label class="p-main">Mensaje *</label>
                                    <textarea class="form-control" formControlName="message" required id="message" placeholder="Mensaje:"></textarea>
                                    <div *ngIf="isFieldValid('message')" class="alert">
                                        Campo requerido (mínimo 10 caracteres)
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-lg-12">
                              <div class="form-group position-relative g-recaptcha" [ngClass]="displayFieldCss('recaptchaReactive')">
                                <re-captcha formControlName="recaptchaReactive"></re-captcha>
                                <div *ngIf="isFieldValid('recaptchaReactive')" class="alert">
                                  Por favor complete el CAPTCHA para continuar
                                </div>
                              </div>
                            </div>
                          </div>

                        <div class="form-row">
                            <div class="col">
                                <button class="btn btn-primary text-center" type="submit" id="submit" name="send" (click)="onSubmit()">
                                    <ng-container *ngIf="isSubmitting; else defaultText">
                                        <div class="spinner-border text-light"></div>
                                    </ng-container>
                                </button>
                                <ng-template #defaultText>Enviar</ng-template>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-5 col-lg-5">
                <div class="row contact-row">
                    <div class="col">
                        <p class="p-main p-contact">teayudo@unen.uy</p>
                    </div>
                </div>
                <div class="row contact-row">
                    <div class="col">
                        <p class="p-main p-contact" style="margin-bottom: 0px;"><i class="fa fa-square"></i>&nbsp;Bogotá, Colombia</p>
                        <p class="p-main p-contact"><strong>57 317 4391585</strong></p>
                    </div>
                </div>
                <div class="row contact-row">
                    <div class="col">
                        <p class="p-main p-contact" style="margin-bottom: 0px;"><i class="fa fa-square"></i>&nbsp;Montevideo, Uruguay</p>
                        <p class="p-main p-contact"><strong>598 95 645 486</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                <a href="https://www.tarjetaceleste.com/" target="_blank">
                    <img class="celeste-logo" src="assets/img/celeste.png">
                </a>
                <a href="https://theparadigmcity.com/" target="_blank">
                    <img class="paradigm-logo" src="assets/img/paradigmcity-black.png">
                </a>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>